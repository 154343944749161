<template>
  <v-container justify="center" class="contentContainer">
    <PageHeader heading="Course Planner"/>
    <v-row class="mt-0" justify="center">
      <v-col class="contentMaxWidth pt-1 px-0">
        <v-tabs v-model="viewMode" color="accent" background-color="background" height="60" slider-size="3" class="align-center">
          <v-tab class="text-capitalize"><v-icon class="mr-2">mdi-trello</v-icon>Kanban</v-tab>
          <v-row class="ma-0 align-center justify-end">
            <v-text-field v-if="$vuetify.breakpoint.lgAndUp" v-model="search" class="planSearchField"
                          label="Search in plan" hide-details dense prepend-inner-icon="mdi-magnify" solo-inverted
                          background-color="border" flat color="accent" clearable @click:clear="search = ''"/>
            <v-menu v-if="$vuetify.breakpoint.smAndUp" transition="slide-y-transition" offset-y left bottom rounded close-on-click nudge-bottom="10">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="accent" class="ml-4 pr-2" outlined v-bind="attrs" v-on="on">
                  Actions
                  <v-icon class="ml-2">mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="timetableConfig.active = true">
                  <v-icon color="accent" class="mr-3">mdi-calendar</v-icon> Generate timetable
                </v-list-item>
                <v-list-item @click="downloadPlan">
                  <v-icon color="accent" class="mr-3">mdi-download</v-icon>Download plan
                </v-list-item>
                <v-list-item @click="insight.display = !insight.display">
                  <v-icon color="accent" class="mr-3">mdi-robot-happy-outline</v-icon>{{ insight.display ? 'Hide' : 'Show' }} Insights
                </v-list-item>
                <v-list-item @click="reset">
                  <v-icon color="accent" class="mr-3">mdi-refresh</v-icon>Reset plan
                </v-list-item>
              </v-list>
            </v-menu>
            <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical inset class="mx-4 my-2"/>
            <v-tooltip bottom max-width="250">
              <template v-slot:activator="{ on, attrs }">
                <v-btn v-bind="attrs" v-on="on" depressed color="accent" :disabled="!planChanged || !validPlan" @click="save">
                  <v-badge :value="planChanged" content="!" color="warning" class="mr-3" overlap>
                    <v-icon small>mdi-content-save</v-icon>
                  </v-badge>
                  Save Plan
                </v-btn>
              </template>
              <span class="font-weight-medium">Plans will be saved locally in your browser if you are not signed in!</span>
            </v-tooltip>
          </v-row>
        </v-tabs>
      </v-col>
    </v-row>

    <v-row class="mt-0"><v-divider/></v-row>

    <v-row justify="center">
      <v-col id="coursePlan" class="contentMaxWidth pt-6 px-0">
        <v-tabs-items v-model="viewMode">
          <v-tab-item v-if="loading" class="text-center mt-12">
            <v-progress-circular indeterminate size="64" width="6" color="accent" class="py-12 my-12"/>
            <p class="text-body-1 text-md-h6 font-weight-regular mb-0">Searching for existing plans...</p>
          </v-tab-item>
          <v-tab-item v-else class="background">
            <vue-position-sticky v-if="$vuetify.breakpoint.smAndUp" :offsetTop="$vuetify.breakpoint.smAndDown ? 55 : 64">
              <v-row class="pt-2 ma-0 background">
                <v-col v-for="(column, idx) in columns" :key="idx" class="py-0 px-1">
                  <v-card outlined rounded color="border">
                    <v-card-text class="d-flex flex-row py-3">
                      <v-icon :color="column.colour" class="mr-2" small>{{ column.icon }}</v-icon>
                      <p class="mb-0 font-weight-bold text--secondary">{{ column.title.toUpperCase() }}</p>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </vue-position-sticky>
            <v-expansion-panels v-model="activeYears" class="pt-2" multiple flat readonly>
              <v-expansion-panel v-for="(section, idx) in plan" :key="section.year">
                <v-expansion-panel-header hide-actions color="background" class="pa-0" :ripple="false">
                  <template v-slot:default="{ open }">
                    <div class="d-flex align-center">
                      <v-icon @click="toggleYear(idx)" class="chevrons">
                        {{ open ? 'mdi-chevron-down' : 'mdi-chevron-right' }}
                      </v-icon>
                      Year {{ section.year }}
                      <p class="ml-2 mb-0 text-caption text--secondary">
                        ({{ Object.entries(section.courses).reduce((acc, obj) => acc + obj[1].length, 0) }} courses)
                      </p>
                      <v-menu transition="slide-y-transition" offset-y bottom rounded close-on-click nudge-bottom="10">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon v-bind="attrs" v-on="on" class="ml-2" small>mdi-dots-horizontal</v-icon>
                        </template>
                        <v-list dense>
                          <v-list-item dense @click="resetYear(idx)">
                            <v-icon color="accent" class="mr-3" small>mdi-refresh</v-icon>Reset year
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-spacer class="ml-8" style="height: 30px !important;" @click="toggleYear(idx)"/>
                    </div>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="px-0 pb-0" color="background">
                  <v-row class="ma-0 pa-0">
                    <v-col v-for="(semester, key) in section.courses" :key="section.year + key" class="py-0 px-1 col-12 col-sm-4">
                      <v-card outlined rounded color="border" class="my-2 my-sm-0">
                        <v-card-title v-if="!$vuetify.breakpoint.smAndUp" class="font-weight-medium pt-3 pb-0">
                          <p class="mb-0 font-weight-bold text--secondary text-capitalize">{{ key }}</p>
                        </v-card-title>
                        <v-card-text class="px-2 py-0">
                          <draggable :list="semester" group="section" @change="onUpdate">
                            <v-card v-for="course in semester" :key="course.code" class="my-2 courseCard" outlined>
                              <span v-show="course.code.includes(search.toUpperCase())">
                                 <v-card-title class="align-center py-3 text-body-1 font-weight-medium">
                                   {{ course.code }}
                                   <p class="text--secondary mb-0 ml-1 text-caption font-weight-medium">• {{ course.campus }}</p>
                                   <v-spacer/>
                                   <v-menu transition="slide-y-transition" offset-y bottom rounded close-on-click nudge-bottom="10">
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-icon v-bind="attrs" v-on="on">mdi-dots-horizontal</v-icon>
                                    </template>
                                    <v-list dense>
                                      <v-list-item dense @click="removeCourse(course.code, section.year, key)">
                                        <v-icon color="error" class="mr-3" small>mdi-delete</v-icon>Remove course
                                      </v-list-item>
                                    </v-list>
                                  </v-menu>
                                </v-card-title>
                                <v-card-subtitle class="pb-2 text-body-2">{{ course.name }}</v-card-subtitle>
                                <v-card-text class="pb-3 d-flex flex-row">
                                  <v-col cols="11" class="pa-0">
                                    <v-chip v-for="(tag, idx) in formatCourseTags(course)" :key="idx" class="mt-1 mr-1 font-weight-medium white--text" :color="tag.colour" small>
                                    {{ tag.label }}
                                  </v-chip>
                                  </v-col>
                                  <v-col cols="1" class="pa-0 text-right d-block align-content-end">
                                    <v-tooltip v-if="insight.display && course.alerts && course.alerts.length > 0" bottom max-width="200" color="tooltip">
                                      <template v-slot:activator="{ on, attrs }">
                                        <v-icon v-bind="attrs" v-on="on" :color="course.alerts[0].type" class="pointer">
                                          {{ course.alerts[0].icon }}
                                        </v-icon>
                                      </template>
                                      <span>{{ course.alerts[0].text }}</span>
                                    </v-tooltip>
                                  </v-col>
                                </v-card-text>
                              </span>
                            </v-card>
                          </draggable>
                        </v-card-text>
                        <v-card-actions :class="semester.length > 0 ? 'pt-0' : 'py-2'" data-html2canvas-ignore>
                          <v-btn depressed text block :disabled="semester.length > 5" @click="openAddCourse(section.year, key)">
                            <p v-if="semester.length < 6" class="mb-0 text--secondary"><v-icon class="mr-1">mdi-plus</v-icon>Add course</p>
                            <v-spacer/>
                            <p v-if="semester.length > 0"
                               :class="semester.length < 7 ? 'mb-0 text--secondary text-caption' : 'mb-0 error--text text-caption font-weight-bold'">
                              {{ semester.length > 6 ? 'Too many courses - ' : '' }}{{ semester.length }} / 6
                            </p>
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-row v-if="plan.length < 6" class="mx-0 mt-8 mb-2" data-html2canvas-ignore>
              <v-btn depressed block text @click="addYear">
                <v-icon class="mr-2">mdi-plus</v-icon>Add year
              </v-btn>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>

    <v-dialog v-model="addCourseConfig.active" max-width="450px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px">
        <v-card-title class="pt-6 pb-4 text-h5 font-weight-bold accent--text">
          Add Courses
          <v-card-subtitle class="pl-2 py-0 text--secondary text-capitalize">
            (Year {{ addCourseConfig.year }} - {{ addCourseConfig.semester }})
          </v-card-subtitle>
        </v-card-title>
        <v-card-text class="pb-0">
          <v-text-field v-model="filterCourse" label="Search course" hide-details dense prepend-inner-icon="mdi-magnify"
                        solo-inverted background-color="border" flat color="accent"/>
          <p class="mt-2 mb-0 text--secondary text-caption">Current courses ({{ addCourseConfig.currentCourses.length }}/6)</p>
          <v-chip-group column class="pt-0 font-weight-medium">
            <v-chip v-for="(course, idx) in addCourseConfig.currentCourses" :key="idx" small color="accent" class="mt-0 mb-1">
              {{ course.code }}
            </v-chip>
          </v-chip-group>
          <v-virtual-scroll height="300" item-height="50" :items="courseList">
            <template v-slot:default="{ item }">
              <v-list-item two-line class="px-1" :ripple="false">
                <v-list-item-action class="mr-4">
                  <v-progress-circular v-if="addCourseConfig.loading && addCourseConfig.newCourses === item" size="24" indeterminate color="accent"/>
                  <v-icon v-else class="ma-0 pointer" :disabled="addCourseConfig.currentCourses.length > 5" @click="addCourse(item)">mdi-checkbox-blank-outline</v-icon>
                </v-list-item-action>
                <v-list-item-content class="py-0">
                  <v-list-item-title>{{ item.slice(0, 8) }}</v-list-item-title>
                  <v-list-item-subtitle>{{ item.slice(10) }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </template>
          </v-virtual-scroll>
        </v-card-text>
        <v-card-actions class="pt-2 pb-3">
          <v-spacer/>
          <v-btn color="accent" class="px-5" depressed @click="addCourseConfig.active = false">Done</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="timetableConfig.active" max-width="450px">
      <v-card :outlined="store.app.darkMode" style="border-radius: 5px">
        <v-card-title class="pt-6 text-h5 font-weight-bold accent--text">Generate Timetable</v-card-title>
        <v-card-text class="pb-2">
          <p class="text--secondary mb-1">Select the year and semesters to generate a timetable for.</p>
          <v-row class="ma-0 align-center">
            <v-col cols="6" class="align-center pl-1">Select a year</v-col>
            <v-col cols="6" class="align-center px-0">
              <v-select v-model="timetableConfig.year" :items="yearsInPlan" item-value="value" item-text="label"
                        outlined flat dense hide-details class="mr-4" label="Year" color="accent"/>
            </v-col>
          </v-row>
          <v-row class="ma-0 align-center">
            <v-col cols="6" class="align-center pl-1">Semesters</v-col>
            <v-col cols="6" class="align-center px-0">
              <v-chip-group v-model="timetableConfig.semesters" multiple :show-arrows="false">
                <SemesterChips v-for="(sem, idx) in columns" :key="idx" :semesters="[sem.title]" icon :small="false"
                               :icon-size="20" icon-class="px-1" :active="timetableConfig.semesters.includes(idx)"/>
              </v-chip-group>
            </v-col>
          </v-row>
          <v-row class="ma-0 align-center">
            <v-col cols="6" class="align-center pl-1">
              <p class="mb-0 text--secondary">
                Ignore semesters
                <Tooltip :size="20">Will add the course regardless of the semester if it is unavailable in the preferred semester</Tooltip>
              </p>
            </v-col>
            <v-col cols="6" class="align-center px-0">
              <v-switch v-model="timetableConfig.ignoreSemesters" color="accent" hide-details inset class="my-0 pl-1"/>
            </v-col>
          </v-row>
          <v-alert class="mt-4 mb-2" border="left" text type="warning" icon="mdi-alert-outline">
            <span class="mb-0 text-body-2">
              Note, that the generated timetable will overwrite any existing <strong>unsaved</strong> timetables!
              Save any changes on the timetable page before continuing.
            </span>
          </v-alert>
        </v-card-text>
        <v-card-actions class="py-3">
          <v-spacer/>
          <v-btn color="accent" text @click="timetableConfig.active = false">Close</v-btn>
          <v-btn color="accent" depressed class="ml-4 px-3" :disabled="disableGenerate" @click="generateTimetable">
            Generate
            <v-icon size="14" color="#FFFFFF" class="ml-1">mdi-open-in-new</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </v-container>
</template>

<script>
import PageHeader from '@/components/PageHeader.vue'
import draggable from 'vuedraggable'
import Tooltip from '@/components/Tooltip.vue'
import html2canvas from 'html2canvas'
import Bugsnag from '@bugsnag/js'
import SemesterChips from '@/components/SemesterChips.vue'

import { useAllStores } from '@/stores/useAllStores'
import { deepClone } from '@/utils/shared/helpers'
import { saveAs } from 'file-saver'

export default {
  name: 'Planner',
  components: {
    SemesterChips,
    Tooltip,
    draggable,
    PageHeader
  },
  setup () {
    return {
      store: useAllStores()
    }
  },
  data: () => ({
    loading: false,
    viewMode: 0,
    search: '',
    filterCourse: '',
    activeYears: [0, 1, 2, 3, 4, 5],
    columns: [
      { title: 'Fall', icon: 'mdi-leaf', colour: 'warning' },
      { title: 'Winter', icon: 'mdi-snowflake-variant', colour: 'primary' },
      { title: 'Summer', icon: 'mdi-flower-tulip', colour: 'error' }
    ],
    tagColours: [],
    plan: [
      {
        year: 1,
        courses: { fall: [], winter: [], summer: [] }
      }
    ],
    existingPlan: null,
    timetableConfig: {
      active: false,
      year: null,
      semesters: [],
      ignoreSemesters: false
    },
    addCourseConfig: {
      active: false,
      loading: false,
      year: null,
      semester: null,
      newCourse: null,
      currentCourses: [],
      newCourses: new Set()
    },
    insight: {
      display: true,
      data: [],
      headers: [
        { text: 'Course', value: 'course' },
        { text: 'Insight', value: 'insight' },
        { text: 'Type', value: 'type' }
      ]
    }
  }),
  mounted () {
    window.addEventListener('beforeunload', this.confirmLeave)

    if (localStorage.getItem('course-plan') != null) {
      this.existingPlan = this.generateIds(JSON.parse(localStorage.getItem('course-plan')))
      this.plan = deepClone(this.existingPlan)
    } else {
      this.existingPlan = deepClone(this.plan)
    }

    if (this.store.user.userInfo) this.getPlan()

    this.tagColours = Object.keys(this.$vuetify.theme.currentTheme).filter(colour => colour.endsWith('Text'))
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.confirmLeave)
  },
  beforeRouteLeave (to, from, next) {
    if (this.planChanged) {
      if (confirm('You have unsaved changes that will be lost if you decide to continue. Are you sure you want to continue?')) {
        next()
      } else {
        return false
      }
    }
    next()
  },
  computed: {
    planChanged () {
      return JSON.stringify(this.plan) !== JSON.stringify(this.existingPlan)
    },
    courseList () {
      return this.store.data.courseList
        .filter(c => c.toLowerCase().includes(this.filterCourse.toLowerCase()))
        .filter(c => !this.takenCourses.includes(c.slice(0, 8)))
    },
    takenCourses () {
      return this.plan
        .filter(section => section.year !== this.addCourseConfig.year - 1)
        .map(section => section.courses)
        .flatMap(sem => [].concat(sem.fall).concat(sem.winter).concat(sem.summer))
        .map(course => course.code)
    },
    yearsInPlan () {
      return this.plan
        .flatMap(year => { return { label: 'Year ' + year.year, value: year.year - 1 } })
    },
    disableGenerate () {
      return this.timetableConfig.year === null || this.timetableConfig.semesters.length < 1
    },
    validPlan () {
      return this.plan
        .map(year => year.courses)
        .flatMap(sem => [sem.fall, sem.winter, sem.summer])
        .map(list => list.length)
        .every(size => size < 7)
    }
  },
  watch: {
    'addCourseConfig.active' (newValue) {
      if (!newValue) this.filterCourse = ''
    },
    'timetableConfig.active' (newValue) {
      if (!newValue) this.timetableConfig = { ...this.timetableConfig, year: null, semesters: [], ignoreSemesters: false }
    }
  },
  methods: {
    generateIds (plan) {
      for (const year of plan) {
        for (const semester of Object.keys(year.courses)) {
          for (const [idx, course] of year.courses[semester].entries()) {
            plan[year.year - 1].courses[semester][idx].id = course.code + semester + (year.year - 1).toString()
          }
        }
      }
      return plan
    },
    formatCourseTags (course) {
      const courseTags = []
      if (course.breadth) courseTags.push(...course.breadth)
      if (course.distribution) courseTags.push(...course.distribution)
      return courseTags.map(tag => {
        return { label: tag, colour: this.tagColours[this.tagCode(tag) % this.tagColours.length] }
      })
    },
    openAddCourse (year, sem) {
      this.addCourseConfig.active = true
      this.addCourseConfig.year = year
      this.addCourseConfig.semester = sem
      this.addCourseConfig.currentCourses = this.plan[year - 1].courses[sem]
    },
    addCourse (course) {
      this.addCourseConfig.newCourses = course
      this.addCourseConfig.loading = true

      this.getCourseInfo(course.slice(0, 8))
    },
    removeCourse (course, year, semester) {
      // eslint-disable-next-line no-return-assign
      const courseIdx = this.plan[year - 1].courses[semester].findIndex((c) => c.code === course)
      if (courseIdx > -1) this.plan[year - 1].courses[semester].splice(courseIdx, 1)
    },
    toggleYear (year) {
      const yearIdx = this.activeYears.indexOf(year)
      if (yearIdx > -1) {
        this.activeYears.splice(yearIdx, 1)
      } else {
        this.activeYears.push(year)
      }
    },
    addYear () {
      this.plan.push({ year: this.plan.length + 1, courses: { fall: [], winter: [], summer: [] } })
    },
    resetYear (year) {
      this.plan[year].courses = { fall: [], winter: [], summer: [] }
      this.$gtag.event('course_plan_reset_year', { value: 1 })
    },
    async getPlan () {
      this.loading = true
      try {
        const resp = await fetch('/rest/v1/plan/myPlan', {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' }
        })

        if (resp.ok) {
          const savedPlan = await resp.json()
          const defaultPlan = JSON.stringify([{ year: 1, courses: { fall: [], winter: [], summer: [] } }])

          let loadSaved = true
          if (JSON.stringify(this.plan) !== JSON.stringify(savedPlan)) {
            if (defaultPlan !== JSON.stringify(this.plan)) {
              if (confirm('An existing plan linked to your account was found. Continue and replace current local plan?') === true) {
                this.existingPlan = savedPlan
                this.plan = deepClone(this.existingPlan)
                localStorage.removeItem('course-plan')
              } else {
                loadSaved = false
              }
            }
            if (loadSaved) {
              this.existingPlan = savedPlan
              this.plan = deepClone(this.existingPlan)
            }
          }
        } else if (resp.statusCode >= 400) {
          console.error(resp)
          this.$toast.error('Error finding existing plans: ' + resp.statusText)
        }
      } catch (e) {
        console.error(e)
      }
      this.loading = false
    },
    async save () {
      localStorage.setItem('course-plan', JSON.stringify(this.plan))
      this.existingPlan = deepClone(this.plan)

      const savePlanRequest = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: this.plan })
      }

      const resp = await fetch('/rest/v1/plan/upsert', savePlanRequest)

      if (resp.ok || !this.store.user.userInfo) {
        this.$toast.info('Course plan saved')
        this.$gtag.event('course_plan_save', { value: 1 })
        if (resp.ok) localStorage.removeItem('course-plan')
      } else {
        console.error(resp)
        this.$toast.error('Could not save the plan: ' + resp.statusText)
      }
    },
    async reset () {
      if (confirm('Are you sure you want to reset your entire course plan? This action cannot be reversed.') === true) {
        const resp = await fetch('/rest/v1/plan/reset', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' }
        })

        if (resp.ok || !this.store.user.userInfo) {
          this.plan = [{ year: 1, courses: { fall: [], winter: [], summer: [] } }]
          this.existingPlan = deepClone(this.plan)
          localStorage.removeItem('course-plan')
          this.$toast.info('Course plan reset')
          this.$gtag.event('course_plan_reset', { value: 1 })
        } else {
          console.error(resp)
          this.$toast.error('Could not reset plan: ' + resp.statusText)
        }
      }
    },
    generateTimetable () {
      const semesterCourses = this.timetableConfig.semesters
        .map(sem => this.columns[sem].title)
        .reduce((semCourses, sem) => {
          semCourses[sem] = this.plan[this.timetableConfig.year].courses[sem.toLowerCase()].map(c => c.code)
          return semCourses
        }, {})
      const generateConfig = {
        courses: { ...semesterCourses },
        options: {
          ignoreSemesters: this.timetableConfig.ignoreSemesters
        }
      }
      this.$gtag.event('course_plan_generate_timetable', { value: 1 })
      const generationLink = this.$router.resolve({ path: '/timetable', query: { generate: encodeURI(JSON.stringify(generateConfig)) } })
      window.open(generationLink.href, '_blank')
    },
    async getCourseInfo (course) {
      const q = {
        query: 'query courseInfo($course: String!) { courseInfo(course: $course) { code, name, campus, breadth, distribution, insights } }',
        variables: { course: course },
        operationName: 'courseInfo'
      }
      fetch('/graphql', {
        method: 'post',
        headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }).then((response) => response.json())
        .then((graphQlRes) => {
          if (graphQlRes.data) {
            const courseInfo = graphQlRes.data.courseInfo[0]
            courseInfo.alerts = this.createInsightAlert(courseInfo)
            courseInfo.id = courseInfo.code + this.addCourseConfig.semester + (this.addCourseConfig.year - 1).toString()
            this.plan[this.addCourseConfig.year - 1].courses[this.addCourseConfig.semester].push(courseInfo)
          } else {
            this.$toast.error(graphQlRes.errors[0].message)
          }
          this.$gtag.event('course_plan_add_' + course, { value: 1 })
          this.addCourseConfig.loading = false
        })
        .catch(() => this.$toast.error('An error occurred when contacting the server. Please try again later.'))
    },
    async fetchCourseInsight (course) {
      const q = {
        query: 'query courseInfo($course: String!) { courseInfo(course: $course) { insights } }',
        variables: { course: course },
        operationName: 'courseInfo'
      }
      const courseInsight = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(q)
      }

      const resp = await fetch('/graphql', courseInsight)

      if (resp.ok) {
        const graphQlRes = await resp.json()
        return graphQlRes.data.courseInfo[0].insights
      } else {
        return { semesters: [] }
      }
    },
    createInsightAlert (course) {
      const semester = this.addCourseConfig.semester
      const semesterRanking = course.insights.semesters
        .toSorted((a, b) => a.rank - b.rank)
        .map(s => s.value.toLowerCase())

      if (semesterRanking.includes(semester)) {
        if (semesterRanking.slice(2).includes(semester)) {
          return [{
            type: 'info',
            icon: 'mdi-information-variant-circle-outline',
            text: 'It might be better to take ' + course.code + ' in the ' + semesterRanking[0] + ' or ' + semesterRanking[1] + ' instead of the ' + semester
          }]
        }
      } else if (semesterRanking.length > 0) {
        return [{
          type: 'warning',
          icon: 'mdi-alert-rhombus-outline',
          text: course.code + ' is historically not offered in the ' + semester
        }]
      }
      return []
    },
    async downloadPlan () {
      const options = {
        logging: false,
        scale: 4,
        backgroundColor: this.store.app.darkMode ? '#0F0F0F' : '#FFFFFF'
      }

      try {
        const plan = document.getElementById('coursePlan')
        const canvas = await html2canvas(plan, options)

        canvas.toBlob((blob) => {
          saveAs(blob, 'UofTIndex-CoursePlan.jpeg', 'image/jpeg')
        })
      } catch (e) {
        console.error(e)
        this.$toast.error('Error when trying to download. Please try again later.')
        Bugsnag.notify(e)
      } finally {
        this.$gtag.event('course_plan_download', { value: 1 })
      }
    },
    async onUpdate (e) {
      if (e.added) {
        let forceRender = false
        const movedCourse = e.added.element
        const map = this.getCoursePlanMap()
        const location = map[movedCourse.id]
        this.addCourseConfig.year = location.year
        this.addCourseConfig.semester = location.sem

        if (!movedCourse.insights) {
          movedCourse.insights = await this.fetchCourseInsight(movedCourse.code)
          forceRender = true
        }

        this.plan[location.year - 1].courses[location.sem][location.index] = {
          ...movedCourse,
          id: movedCourse.code + location.sem + (location.year - 1).toString(),
          alerts: this.createInsightAlert(movedCourse)
        }

        if (forceRender) this.$forceUpdate()
      }
    },
    getCoursePlanMap () {
      const map = {}
      for (const year of this.plan) {
        for (const semester of Object.keys(year.courses)) {
          for (const [idx, course] of year.courses[semester].entries()) {
            map[course.id] = { year: year.year, sem: semester, index: idx }
          }
        }
      }
      return map
    },
    tagCode (tag) {
      return Math.abs(tag.split('').map(s => s.charCodeAt(0) - 97).reduce((sum, num) => sum + num, 0)) * 5
    },
    confirmLeave (event) {
      if (this.planChanged) {
        event.preventDefault()
        event.returnValue = 'Unsaved changes...'
      }
    }
  }
}
</script>

<style scoped>
  .background {
    background: var(--v-background-base);
  }
  .planSearchField {
    max-width: 365px !important;
  }
  .v-expansion-panels {
    background: var(--v-background-base);
  }
  >>>.v-expansion-panel-header {
    background: var(--v-background-base);
  }
  >>>.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: 40px !important;
  }
  >>>.v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
  >>>.vue-position-sticky {
    z-index: 2 !important;
  }
  >>>.v-list--two-line .v-list-item, .v-list-item--two-line {
    min-height: 50px !important;
  }
  >>>.v-badge__badge {
    height: 15px;
    min-width: 1px;
    font-weight: bold;
    padding: 2px 6px;
    margin: 0 0 4px 6px;
  }
  .courseCard {
    cursor: move;
  }
  .chevrons:focus::after {
    opacity: 0 !important;
  }

</style>
